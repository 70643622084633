.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.signup-container {
  position: relative; /* Add relative positioning */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.signup-container h2 {
  margin-bottom: 20px;
}

.signup-form label {
  display: block;
  margin-bottom: 8px;
}

.signup-form input,
.signup-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.signup-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-form button:hover {
  background-color: #0056b3;
}

.close-icon {
  position: absolute; /* Absolute positioning */
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-icon svg {
  font-size: 24px;
  color: #333;
}

.mobile-input {
  position: relative;
  display: inline-block;
}

.prefix {
  position: absolute;
  left: 8px; // Adjust as needed
  top: 35%;
  font-weight: bold; // Make the prefix bold
  transform: translateY(-50%);
  font-size: 16px; // Adjust as needed
  color: rgba(0, 0, 0, 0.3); // Adjust as needed
}

#mobile {
  padding-left: 45px; // Adjust based on the width of the prefix
}

.terms-privacy {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.terms-privacy a {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}

.terms-privacy a:hover {
  text-decoration: underline;
}

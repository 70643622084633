/* Modal container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Default width for mobile and tablet */
  max-width: 90%; /* Max-width for desktop */
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center; /* Center-align text */
}

/* Overlay */
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Form */
.form {
  display: flex;
  flex-direction: column;
}

.form label {
  margin-bottom: 8px;
}

.form input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-links {
  display: flex;
  justify-content: center; /* Align items to the center */
  margin-bottom: 16px; /* Add spacing between links and form */
}

.form-links label {
  margin-right: 16px; /* Add spacing between checkbox and link */
}

.form-links a {
  text-decoration: none;
  color: #007bff;
}

.form-links a:hover {
  text-decoration: underline;
}

.form button {
  padding: 10px 20px;
  // background-color: #007bff;
  background-color: #212121;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  width: 50%;
  margin: auto;
}

.form button:hover {
  background-color: #0056b3;
}

/* Media queries for different screen sizes */
@media only screen and (min-width: 768px) {
  /* Tablet and above */
  .modal {
    width: 90%;
    max-width: 90%;
  }
}

@media only screen and (min-width: 992px) {
  /* Desktop and above */
  .modal {
    width: 40%;
    max-width: 40%;
  }
}
